import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { storeFCMToken } from "./services/api";

const firebaseConfig = {
  apiKey: "AIzaSyDdSqGyE25Dav12pfyimfS5YAiMqd0wBQ0",
  authDomain: "sats-formiga-mg.firebaseapp.com",
  projectId: "sats-formiga-mg",
  storageBucket: "sats-formiga-mg.appspot.com",
  messagingSenderId: "440048848962",
  appId: "1:440048848962:web:d183fc5b292bc249c19ddb",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = async (userId) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BKmtoU3xyZcC3CcdZ9Z9Em72oNaqBcw9ovpNgBNkydFKfWuRaVa_Ty9t43vs0PhPASSCTPc3-dzz_80WZQhdjZg",
    });
    if (currentToken) {
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
      await storeFCMToken(userId, currentToken);
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

import { Route, Routes } from "react-router-dom";
import Private from "./Private";
import Auth from "../pages/Auth";
import Home from "../pages/Home";
import Details from "../pages/Details";

const AppRoutes = () => {
  return (
    <Routes>
      <Route exaxt path="/login" element={<Auth />} />
      <Route
        exaxt
        path="/"
        element={
          <Private>
            <Home />
          </Private>
        }
      />
      <Route
        exaxt
        path="/details"
        element={
          <Private>
            <Details />
          </Private>
        }
      />
    </Routes>
  );
};

export default AppRoutes;

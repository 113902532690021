import Switch from "react-switch";

const RenderReceiveNotificationsSwitch = ({
  receiveNotifications,
  handleReceiveNotification,
}) => {
  return (
    <label className="d-flex justify-content-between" htmlFor="material-switch">
      <span className="fs-5">Receber notificações</span>
      <Switch
        checked={receiveNotifications}
        onChange={handleReceiveNotification}
        onColor="#86d3ff"
        onHandleColor="#2693e6"
        handleDiameter={30}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
        className="react-switch"
        id="material-switch"
      />
    </label>
  );
};

export default RenderReceiveNotificationsSwitch;

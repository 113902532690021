import { Navigate } from "react-router-dom";
import { useAuth} from "../../contexts/auth";

const Private = ({ children }) => {
  const { authenticated, loading } = useAuth();

  if (loading) {
    return <div className="loading">Carregando...</div>;
  }

  if (!authenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default Private;

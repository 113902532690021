import { useEffect, useState } from "react";
import moment from "moment";
import Switch from "react-switch";

import { useAuth } from "../../contexts/auth";
import { update } from "../../services/api";
import Modal from "../../components/Modal";

const Actions = ({ manual, automatic, pointId, pumpStatus }) => {
  const [isAutomaticChecked, setIsAutomaticChecked] = useState();
  const [isManualChecked, setIsManualChecked] = useState();
  const [isAutomaticDisabled, setIsAutomaticDisabled] = useState(true);
  const [isManualDisabled, setIsManualDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    setHasPermission(user.role === 2 || user.role === 3);
  }, [user.role]);

  useEffect(() => {
    if (manual && manual.status !== null) {
      setIsManualChecked(manual.status);
    }

    if (automatic && automatic.enabled === null) {
      setIsManualDisabled(false);
      setIsAutomaticChecked(false);
      setIsAutomaticDisabled(true);
    } else {
      setIsManualDisabled(isAutomaticChecked);
      setIsAutomaticChecked(automatic.enabled);
      setIsAutomaticDisabled(false);
    }
    setLoading(false);
  }, [manual]);

  useEffect(() => {
    setIsManualDisabled(isAutomaticChecked);
  }, [isAutomaticChecked]);

  const handleAutomatic = async (nextChecked) => {
    setLoading(true);
    const response = await update(pointId, { enabled: nextChecked });
    setIsAutomaticChecked(nextChecked);
    response.status === 204 && setLoading(false);
  };

  const handleManual = async (nextChecked) => {
    setLoading(true);
    const currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
    const response = await update(pointId, {
      command: nextChecked,
      dateTime: `'${currentDateTime}'`,
    });
    setIsManualChecked(nextChecked);
    pumpStatus === isManualChecked && setLoading(false);
  };

  return (
    <>
      {loading && <Modal />}
      <hr />
      <h3 className="text-center">Acionamento</h3>
      <label>
        <Switch
          onChange={handleAutomatic}
          checked={isAutomaticChecked ?? false}
          className="align-middle me-2"
          onColor="#0d6efd"
          offColor="#dc3545"
          disabled={!hasPermission || isAutomaticDisabled}
        />
        <span
          style={{
            color: !hasPermission || isAutomaticDisabled ? "#ccc" : "inherit",
          }}
        >
          Automático
        </span>
      </label>
      <label className="mt-3">
        <Switch
          onChange={handleManual}
          checked={isManualChecked ?? false}
          className="align-middle me-2"
          onColor="#0d6efd"
          offColor="#dc3545"
          disabled={!hasPermission || isManualDisabled}
        />
        <span
          style={{
            color: !hasPermission || isManualDisabled ? "#ccc" : "inherit",
          }}
        >
          Manual
        </span>
      </label>
    </>
  );
};

export default Actions;

import { useEffect, useState } from "react";

import { useAuth } from "../../contexts/auth";
import { fetchToken } from "../../firebase";

import Header from "./Header";
import Modal from "../../components/Modal";
import Footer from "../../components/Footer";
import NotificationToast from "./NotificationToast";
import CardList from "./CardList";

const Home = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user.id) {
      fetchToken(user.id);
    }
  }, []);

  return (
    <div>
      {loading && <Modal />}

      <Header />

      {/*<NotificationToast />*/}

      <CardList setLoading={setLoading} />

      <Footer />
    </div>
  );
};

export default Home;

import pump from "../../../assets/ic_pump.png";
import level from "../../../assets/ic_level.png";
import flow from "../../../assets/ic_flow.png";
import multk from "../../../assets/ic_multk.png";

export const getDate = (dateTime) => {
  if (dateTime == null) return "";
  const date = dateTime.split(" ")[0];
  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year.substring(2, 4)}`;
};

export const getTime = (dateTime) => {
  if (dateTime == null) return "";
  const time = dateTime.split(" ")[1];
  return time.substring(0, 5);
};

export const getIcon = (type) => {
  switch (type) {
    case "1":
      return level;
    case "2":
      return pump;
    case "3":
      return flow;
    case "4":
      return level;
    case "5":
      return multk;
    default:
      break;
  }
};

export const getReading = (type, reading, isOffline) => {
  switch (type) {
    case "1":
    case "4":
      return (
        <>
          <span>Nível: </span>
          <span className={handleClassName(isOffline)}>{reading} m.c.a</span>
        </>
      );
    case "2":
      return (
        <>
          <span>Bomba: </span>
          {reading === "1.00" ? (
            <span className={handleClassName(isOffline, "text-success")}>
              Ligada
            </span>
          ) : (
            <span className={handleClassName(isOffline, "text-danger")}>
              Desligada
            </span>
          )}
        </>
      );
    case "3":
      return (
        <>
          <span>Vazão: </span>
          <span className={handleClassName(isOffline)}>{reading} l/s</span>
        </>
      );
    case "5":
      return (
        <>
          <span>Corrente: </span>
          <span className={handleClassName(isOffline)}>{reading} A</span>
        </>
      );
    default:
      break;
  }
};

const handleClassName = (isOffline, fontWeight = "fw-normal") => {
  return `${isOffline ? "disconnected" : fontWeight}`;
};

export const getConnectionStatus = (isOffline) => {
  return isOffline ? (
    <span className="disconnected">Desconectado</span>
  ) : (
    <span className="connected">Conectado</span>
  );
};

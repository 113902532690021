import { ButtonGroup, ToggleButton } from "react-bootstrap";

const TimeIntervalToggle = ({
  timeIntervalOptions,
  radioValue,
  onChange,
  disableRadioButtons,
}) => {
  return (
    <label className="d-flex justify-content-between mt-3">
      <span className="fs-5">Intervalo</span>
      <ButtonGroup>
        {timeIntervalOptions.map((value) => (
          <ToggleButton
            key={value}
            id={`radio-${value}`}
            type="radio"
            variant="outline-primary"
            name="radio"
            value={value}
            checked={radioValue === value}
            onChange={(e) => onChange(parseInt(e.currentTarget.value))}
            disabled={disableRadioButtons}
          >
            {value}
          </ToggleButton>
        ))}
      </ButtonGroup>
      <span className="fs-5">min.</span>
    </label>
  );
};

export default TimeIntervalToggle;

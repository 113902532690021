import { useNavigate } from "react-router-dom";

const DetailsHeader = (props) => {
  const point = props.point;
  const dateTime = props.dateTime;
  const navigate = useNavigate();

  return (
    <div
      className={`${
        point.alarm ? "bg-danger" : "bg-primary"
      } bg-gradient text-white p-2`}
    >
      <div className="d-flex justify-content-between align-items-center">
        <i
          className="bi bi-chevron-left text-white fs-1"
          onClick={() => navigate("/")}
        ></i>
        <p className="text-end pt-1">{dateTime || ""}</p>
      </div>
      <h2 className="text-center">{point.name || "NOME DO PONTO"}</h2>
      {point.alarm && <p className="text-center mb-0">{point.alarm}</p>}
    </div>
  );
};

export default DetailsHeader;

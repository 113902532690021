import { useState, useEffect } from "react";
import {
  Form,
  Button,
  Figure,
  Row,
  Col,
  Container,
  Alert,
} from "react-bootstrap";
import Modal from "../../components/Modal";
import { useAuth } from "../../contexts/auth";
import logo from "../../assets/logoWeb.png";

const Auth = () => {
  const { login, loginFailed, loginFailedMessage } = useAuth();

  const [username, setUsername] = useState(
    process.env.REACT_APP_USERNAME || ""
  );
  const [password, setPassword] = useState(
    process.env.REACT_APP_PASSWORD || ""
  );
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [loginFailed]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    setValidated(true);
    setLoading(true);

    if (!form.checkValidity()) {
      e.stopPropagation();
      setLoading(false);
      return;
    }

    login(username, password);
    // setLoading(false);
  };

  const handleLoginFailedMessage = () => {
    switch (loginFailedMessage) {
      case "User not found":
        return "Usuário e/ou senha inválidos.";
      case "Connection timed out":
      case "Connection refused":
        return "Servidor desconectado.";
      case "Expired token":
        return "Sessão expirada.";
      default:
        return "";
    }
  };

  return (
    <Container fluid className="">
      {loading && <Modal />}
      <Container>
        <Row
          className="justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <Col md={5}>
            <Figure className="d-flex justify-content-center">
              <Figure.Image width={100} height={100} alt="logo" src={logo} />
            </Figure>

            {loginFailed && (
              <Alert
                key={"danger"}
                variant={"danger"}
                className={"text-center"}
              >
                {handleLoginFailedMessage()}
              </Alert>
            )}

            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className="border border-primary border-1 rounded p-3 bg-color shadow-lg"
            >
              <Form.Floating className="mb-3">
                <Form.Control
                  required
                  id="floatingInputCustom"
                  type="text"
                  placeholder="Usuário"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <label htmlFor="floatingInputCustom">Usuário</label>
                <Form.Control.Feedback type="invalid">
                  Por favor informe o Usuário.
                </Form.Control.Feedback>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Control
                  required
                  id="floatingPasswordCustom"
                  type="password"
                  placeholder="Senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor="floatingPasswordCustom">Senha</label>
                <Form.Control.Feedback type="invalid">
                  Por favor informe a Senha.
                </Form.Control.Feedback>
              </Form.Floating>

              <div className="d-grid">
                <Button variant="primary" type="submit" size="lg">
                  Entrar
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Auth;

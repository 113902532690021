import {
  Container,
  Form,
  Row,
  Col,
  InputGroup,
  Figure,
} from "react-bootstrap/";

import { useSearch } from "../../contexts/search";
import logo from "../../assets/logoWeb.png";
import Settings from "./Settings";

const Header = () => {
  const { search, setSearch } = useSearch();

  return (
    <Container
      fluid
      className="sticky-top bg-primary bg-gradient border-bottom"
    >
      <Row className="justify-content-center">
        <Col
          md={7}
          lg={5}
          className="d-flex flex-row align-items-center justify-content-evenly"
        >
          <Figure className="mt-2 mb-0">
            <Figure.Image width={80} height={80} alt="logo" src={logo} />
          </Figure>
          <div className="d-flex flex-column align-items-center ms-2">
            <h2 className="text-center text-white fst-italic mt-2 mb-0">
              {process.env.REACT_APP_NAME}
            </h2>
            <InputGroup size="sm" className="mt-2 mb-3">
              <Form.Control
                id="search"
                type="search"
                className="text-uppercase text-center"
                style={{ boxShadow: "none" }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <InputGroup.Text>
                <i className="bi bi-search"></i>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className="float-end ms-2">
            <Settings />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card as BsCard, Row, Col, Figure } from "react-bootstrap";

import { isDisconnected } from "../../utils/readingInterval";
import {
  getDate,
  getTime,
  getIcon,
  getReading,
  getConnectionStatus,
} from "./configurations/cardConfig";

const Card = ({ point }) => {
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    setIsOffline(isDisconnected(point.dateTime));
  }, [point]);

  return (
    <BsCard
      bg="light"
      text="dark"
      border={point.alarm ? "danger" : "primary"}
      className="mt-3 shadow"
    >
      <BsCard.Header
        className={`text-bg-${point.alarm ? "danger" : "primary"} text-center`}
        as="h5"
      >
        {point.name}
        {point.alarm && <p className="fs-6 mb-0">{point.alarm}</p>}
      </BsCard.Header>
      <BsCard.Body
        className={`card-body ${point.alarm ? "bg-color-alarm" : "bg-color"}`}
      >
        <Link
          to={"/details"}
          state={point}
          className="text-decoration-none text-black"
        >
          <Row>
            <Col className="d-flex align-items-center">
              <Figure>
                <Figure.Image
                  width={75}
                  height={75}
                  alt="icon"
                  src={getIcon(point.pointType)}
                />
              </Figure>
            </Col>
            <Col xs={6}>
              <BsCard.Text className="mb-0">
                {getReading(point.pointType, point.reading, isOffline)}
              </BsCard.Text>
              <BsCard.Text>
                <span>Status:</span> {getConnectionStatus(isOffline)}
              </BsCard.Text>
            </Col>
            <Col className="text-end">
              <BsCard.Text className="mb-0">
                {getDate(point.dateTime)}
              </BsCard.Text>
              <BsCard.Text>{getTime(point.dateTime)}</BsCard.Text>
            </Col>
          </Row>
        </Link>
      </BsCard.Body>
    </BsCard>
  );
};

export default Card;

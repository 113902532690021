import { useState, useEffect } from "react";
import { Button, Offcanvas } from "react-bootstrap";

import { useAuth } from "../../../contexts/auth";
import { updateConfig } from "../../../services/api";

import TimeIntervalToggle from "./TimeIntervalToggle";
import RenderReceiveNotificationsSwitch from "./RenderReceiveNotificationsSwitch";
import RenderSaveButton from "./RenderSaveButton";

const Settings = () => {
  const { user } = useAuth();

  const [show, setShow] = useState(false);
  const [receiveNotifications, setReceiveNotifications] = useState(false);
  const [disableRadioButtons, setDisableRadioButtons] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const timeIntervalOptions = [1, 5, 10, 30, 60];
  const [radioValue, setRadioValue] = useState(5);

  const handleInitialValues = () => {
    setReceiveNotifications(user?.receiveNotifications ?? false);
    setDisableRadioButtons(!user?.receiveNotifications ?? false);
    setRadioValue(user?.timeInterval ?? 5);
  };

  useEffect(() => {
    handleInitialValues();
  }, [user]);

  useEffect(() => {
    setDisableSaveButton(
      receiveNotifications === user.receiveNotifications &&
        radioValue === user.timeInterval
    );
  }, [receiveNotifications, radioValue]);

  const handleReceiveNotification = () => {
    setReceiveNotifications(!receiveNotifications);
    setDisableRadioButtons(receiveNotifications);
  };

  const handleSaveConfigurations = async () => {
    setLoading(true);
    const response = await updateConfig(
      user.id,
      receiveNotifications,
      radioValue
    );

    //console.log(response);

    if (response.status === 204) {
      setDisableSaveButton(true);
      user.receiveNotifications = receiveNotifications;
      user.timeInterval = radioValue;
      localStorage.setItem("asu", JSON.stringify(user));
    }

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const handleClose = () => {
    setShow(false);
    handleInitialValues();
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-gear text-white fs-6"></i>
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="bg-color"
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          className="bg-primary"
        >
          <Offcanvas.Title className="text-white fs-3">
            Configurações
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="m-3 p-4">
          <RenderReceiveNotificationsSwitch
            receiveNotifications={receiveNotifications}
            handleReceiveNotification={handleReceiveNotification}
          />

          <TimeIntervalToggle
            timeIntervalOptions={timeIntervalOptions}
            radioValue={radioValue}
            onChange={setRadioValue}
            disableRadioButtons={disableRadioButtons}
          />

          <RenderSaveButton
            disableSaveButton={disableSaveButton}
            isLoading={isLoading}
            handleSaveConfigurations={handleSaveConfigurations}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Settings;

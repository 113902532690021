import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <Container>
      <Row className="justify-content-center fixed-bottom bg-primary border-top border-white">
        <Col md={7} lg={5} className="text-center text-white mt-2">
          <h6>Sagatech &copy; 2023</h6>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;

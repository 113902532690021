export const getDate = (dateTime) => {
  const date = dateTime.split(" ")[0];
  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}`;
};

export const getTime = (dateTime) => {
  const time = dateTime.split(" ")[1];
  return time;
};

export const configurePoint = (pointType, setReadingType, setUnit) => {
  switch (pointType) {
    case "1":
      setReadingType(["Nível"]);
      setUnit(["m.c.a."]);
      break;

    case "2":
      setReadingType(["Bomba"]);
      setUnit("");
      break;

    case "3":
      setReadingType(["Vazão", "Volume"]);
      setUnit(["l/s", "m³"]);
      break;

    case "4":
      setReadingType(["Nível"]);
      setUnit(["m.c.a."]);
      break;

    case "5":
      setReadingType([
        "Corrente",
        "Potência",
        "Consumo",
        "Tensão fase 1",
        "Tensão fase 2",
        "Tensão fase 3",
      ]);
      setUnit(["A", "kW", "kW/h", "V", "V", "V"]);
      break;

    default:
      break;
  }
};

import { Button, Spinner } from "react-bootstrap";

const RenderSaveButton = ({
  disableSaveButton,
  isLoading,
  handleSaveConfigurations,
}) => {
  return (
    <div className="d-grid position-absolute bottom-0 start-50 translate-middle">
      <Button
        variant="primary"
        size="lg"
        disabled={disableSaveButton}
        onClick={!isLoading ? handleSaveConfigurations : null}
      >
        {isLoading ? (
          <div>
            <Spinner as="span" animation="border" size="sm" />
            <span className="ms-2">Salvando...</span>
          </div>
        ) : (
          "Salvar"
        )}
      </Button>
    </div>
  );
};

export default RenderSaveButton;

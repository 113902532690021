import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./contexts/auth";
import { SearchProvider } from "./contexts/search";
import AppRoutes from "./routes/AppRoutes";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

function App() {
  return (
    <Router>
      <AuthProvider>
        <SearchProvider>
          <AppRoutes />
        </SearchProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;

import Actions from "./Actions";

const InfoBody = (props) => {
  return (
    <div>
      <span className="fw-bold">{props.readingType}: </span>
      {props.value} {props.unit}
    </div>
  );
};

const Info = (props) => {
  const point = props.point;
  const lastReading = props.lastReading;
  const readingType = props.readingType;
  const unit = props.unit;
  const disconnected = props.disconnected;
  const manualPumpDrive = props.manualPumpDrive;
  const automaticPumpDrive = props.automaticPumpDrive;

  const handleValue = (reading) => {
    if (readingType[0] === "Bomba")
      return reading === "1.00" ? "Ligada" : "Desligada";

    return reading;
  };

  const handlePumpStatus = () =>
    Array.isArray(lastReading) ?? lastReading[0] === "1.00";

  return (
    <div className="px-3 py-2 fs-3 d-flex flex-column">
      {Array.isArray(lastReading)
        ? lastReading.map((reading, index) => (
            <InfoBody
              readingType={readingType[index]}
              value={handleValue(reading)}
              unit={unit[index]}
              key={index}
            />
          ))
        : disconnected && (
            <span className="fw-bold text-center disconnected">
              Desconectado
            </span>
          )}

      {point.pointType === "2"
        ? !props.disconnected && (
            <Actions
              manual={manualPumpDrive}
              automatic={automaticPumpDrive}
              pointId={point.pointId}
              pumpStatus={handlePumpStatus()}
            />
          )
        : null}
      <hr />
    </div>
  );
};

export default Info;
